import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rgba } from "polished";
import { CSSTransition } from "react-transition-group";
import BasicButton from "components/BasicButton";
import Logo from "components/Logo";
import ConversationThreadTimelineMembers from "components/ConversationThread/TimelineMembers";
import parseError from "utils/parseError";
import SCHEDULED_FILTERS from "features/Inbox/constants/scheduledFilters";

const Root = styled.div`
  position: relative;
  flex: 0 0 auto;
  z-index: 30;
`;

const Header = styled.header`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  position: absolute;
  bottom: 100%;
  width: 100%;
  left: 0;
  background: linear-gradient(
    to bottom,
    ${(props) => {
      return rgba(props.theme.colors.background.paper, 0);
    }},
    ${(props) => {
      return props.theme.colors.background.paper;
    }}
  );
`;

const ToggleButton = styled(BasicButton)`
  position: relative;
  border-radius: 6px;
  padding: 5px 20px 5px 10px;
  font-weight: 600;
  font-size: ${(props) => {
    return props.theme.fonts.miniButtonFontSize;
  }};
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  text-transform: uppercase;
  margin: 10px auto;
  color: ${(props) => {
    return props.isActive
      ? props.theme.colors.primary.main
      : props.theme.colors.text.primary;
  }};

  &:hover {
    background: ${(props) => {
      return props.theme.colors.action.hover;
    }};
  }

  &:after {
    content: "▲";
    position: absolute;
    right: 6px;
    top: 50%;
    font-size: 0.5rem;
    transition: all ease 0.3s;
    transform: translateY(-50%)
      rotate(
        ${(props) => {
          return props.isActive ? 180 : 0;
        }}deg
      );
  }
`;

const Content = styled.div`
  transition: height cubic-bezier(0.175, 0.885, 0.32, 1) 0.666s;
  height: ${(props) => {
    return props.isActive ? props.height : 0;
  }};
  max-height: calc(100vh - 305px);
  background: ${(props) => {
    return props.theme.colors.background.default;
  }};
  overflow: auto;
  position: relative;
  border-top: ${(props) => {
    return (
      props.isActive &&
      props.theme.mixins.border({ color: props.theme.colors.divider })
    );
  }};
`;

const Error = styled.div`
  background: ${(props) => {
    return props.theme.colors.error.main;
  }};
  padding: 10px;
  color: ${(props) => {
    return props.theme.colors.primary.contrastText;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.tinyFontSize;
  }};
  text-align: center;
`;

const Loading = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  border-radius: ${(props) => {
    return props.theme.defaults.bubbleBorderRadius;
  }};
  background: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 999;
  height: 25px;
  width: 50px;
  transform: translate(-50%, 0);

  & > div {
    display: flex;
    padding: 0 8px;

    & > * {
      height: 100%;
    }
  }

  &.fade-enter {
    transform: translate(-50%, 250px);
  }

  &.fade-enter-active {
    transform: translate(-50%, -10px);
    transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.5s;
  }

  &.fade-exit {
    transform: translate(-50%, -10px);
  }

  &.fade-exit-active {
    transform: translate(-50%, 250px);
    transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.5s;
    transition-delay: 1s;
  }
`;

class ScheduledMessages extends Component {
  static propTypes = {
    renderMessage: PropTypes.func,
    activeConversationFilterSlug: PropTypes.string.isRequired,
    appSettings: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    timeline: PropTypes.object,
    height: PropTypes.any,
    containerQueryParams: PropTypes.object,
    updateMessageRequest: PropTypes.func,
    deleteMessageRequest: PropTypes.func,
    scheduledMessagesContainer: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isActive: SCHEDULED_FILTERS.includes(props.activeConversationFilterSlug),
    };
  }

  toggleActive = () => {
    return this.setState((prevState) => {
      return { isActive: !prevState.isActive };
    });
  };

  renderErrors = (error) => {
    return <Error>{error.title}</Error>;
  };

  renderLoading = () => {
    return (
      <Loading>
        <Logo animate dotColor="contrast" color="transparent" width={28} />
      </Loading>
    );
  };

  render() {
    const { isActive } = this.state;
    const {
      height,
      currentUser,
      timeline,
      containerQueryParams,
      appSettings,
      updateMessageRequest,
      deleteMessageRequest,
      scheduledMessagesContainer: {
        substate: { isUpdating, errorUpdating },
      },
    } = this.props;
    const parsedErrors = errorUpdating && parseError(errorUpdating);
    return (
      <Root>
        <Header>
          <ToggleButton
            data-testid="toggle-scheduled-messages"
            isActive={isActive}
            onClick={() => {
              return this.toggleActive();
            }}
          >
            Scheduled Messages
          </ToggleButton>
        </Header>
        {parsedErrors && this.renderErrors(parsedErrors)}
        <Content height={height ?? "300px"} isActive={isActive}>
          <ConversationThreadTimelineMembers
            scheduled
            appSettings={appSettings}
            timeline={timeline}
            containerQueryParams={containerQueryParams}
            updateMessageRequest={updateMessageRequest}
            deleteMessageRequest={deleteMessageRequest}
            currentUser={currentUser}
            renderMessage={this.props.renderMessage}
          />
          <CSSTransition
            key="loading-overlay"
            timeout={{ enter: 0, exit: 1500 }}
            classNames="fade"
            in={isUpdating}
            mountOnEnter
            unmountOnExit
          >
            {this.renderLoading()}
          </CSSTransition>
        </Content>
      </Root>
    );
  }
}

export default ScheduledMessages;
