import PropTypes from "prop-types";
import get from "lodash/get";

import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

import GridButton from "../GridButton";
import canAccessMultipleAccounts from "utils/canAccessMultipleAccounts";

function MenuItems({ closeMenu, currentAccount, currentUser }) {
  const supportLink = get(
    currentAccount,
    ["settings", "supportLink", "value"],
    "https://help.textus.com/s",
  );

  const menuItems = () => {
    return [
      {
        key: "dashboard",
        title: "Dashboard",
        to: "/dashboard",
        Icon: DashboardIcon,
        shouldRender: true,
      },
      {
        key: "accounts",
        title: "Accounts",
        to: "/accounts",
        Icon: SupervisedUserCircleIcon,
        shouldRender: canAccessMultipleAccounts(currentUser),
      },
      {
        key: "profile",
        title: "Profile",
        to: "/profile",
        Icon: PersonIcon,
        shouldRender: true,
      },
      {
        key: "status",
        title: "Status",
        Icon: LocalHospitalIcon,
        shouldRender: true,
        href: "https://status.next.textus.com/",
      },
      {
        key: "support",
        title: "Support",
        Icon: ContactSupportIcon,
        shouldRender: true,
        href: supportLink || "#",
      },
    ];
  };

  const renderItem = ({
    key,
    to,
    href,
    Icon,
    title,
    onClick = closeMenu,
    className,
  }) => {
    return (
      <ImageListItem key={key} cols={1} className={className}>
        <GridButton
          data-testid={title}
          aria-label={`Go To ${title}`}
          clickHandler={onClick}
          to={to}
          href={href}
          title={title}
          icon={<Icon fontSize="large" />}
        />
      </ImageListItem>
    );
  };

  return (
    <Box p={2}>
      <ImageList cellHeight={80} cols={3} style={{ margin: "0" }}>
        {menuItems()
          .filter((i) => {
            return i.shouldRender;
          })
          .map((item) => {
            return renderItem(item);
          })}
      </ImageList>
    </Box>
  );
}

MenuItems.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default MenuItems;
