import { useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Dialog,
  Theme,
  useMediaQuery,
  DialogContent,
} from "@mui/material";

import { Attachment } from "@tesseract/core";
import { KeywordModalProps } from "../models/AutomationModels";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";

import MessageInputFormRow from "components/MessageInputFormRow";
import SavedRepliesOption from "components/MessageInputOptions/SavedRepliesOption";

import { KeywordAutomationSchema } from "formHelpers/validationSchemas";
import { useCurrentAccount } from "hooks";

import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";
import { createMessageBody } from "utils/createMessageBody";
import { DialogHeader } from "components/DialogHeader";
import { DialogFooter } from "components/DialogFooter";

const createCopy = {
  title: "Create keyword",
  modalLabel: "Create Keyword Modal",
  keywordDescription:
    "Add a keyword to trigger an auto-response when received from a contact.",
  autoResponseDescription:
    "Add an auto-response to send when a contact sends the keyword above.",
  callToAction: "Create",
  callToActionLabel: "Create Keyword",
};
const editCopy = {
  title: "Edit keyword",
  modalLabel: "Edit Keyword Modal",
  keywordDescription: "",
  autoResponseDescription:
    "Edit an auto-response to send when a contact sends the keyword above.",
  callToAction: "Save",
  callToActionLabel: "Edit Keyword",
};

function KeywordModal({
  editModal,
  handleClose,
  handleToggleSignature,
  isSignatureActive,
  keywordValues,
  open,
  signature,
  submitKeywords,
}: KeywordModalProps) {
  const fullScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("md");
  });
  const currentAccount = useCurrentAccount();
  const { conversationMaxMessageLength } = currentAccount.settings;
  const maxCharacterLength =
    conversationMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;

  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: currentAccount.shortenedLinkPreviewUrl,
  });

  const handleSubmit = (
    values: {
      keyword: string;
      autoResponse: string;
      attachments: Attachment.Model[];
    },
    actions: any,
  ) => {
    const { autoResponse } = values;
    const body = createMessageBody(
      autoResponse,
      isSignatureActive,
      signature?.content ?? "",
      shortenedLink,
    );
    const params = { ...values, autoResponse: body };
    setShortenedLink({ ...shortenedLink, fullLink: "" });
    return submitKeywords(params, actions);
  };

  const modalCopy = editModal ? editCopy : createCopy;

  return (
    <Dialog
      aria-label={modalCopy.modalLabel}
      onClose={handleClose}
      fullScreen={fullScreen}
      open={open}
    >
      <DialogHeader title={modalCopy.title} onClose={handleClose} />
      <Formik
        initialValues={keywordValues}
        onSubmit={handleSubmit}
        validationSchema={KeywordAutomationSchema(maxCharacterLength)}
      >
        {({
          dirty,
          errors,
          isSubmitting,
          isValid,
          setFieldValue,
          touched,
          values,
        }) => {
          return (
            // @ts-expect-error: repo-wide type issue with Formik <Form> component
            <Form aria-label="Keyword Form" placeholder="Keyword Form">
              <DialogContent>
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    <Typography fontWeight={400}>
                      {modalCopy.keywordDescription}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="keyword" type="text">
                      {({ field }: FieldProps) => {
                        return (
                          <TextField
                            {...field}
                            disabled={editModal}
                            error={touched.keyword && !!errors.keyword}
                            fullWidth
                            helperText={
                              (touched.keyword && errors.keyword) ||
                              (editModal && "Keyword cannot be edited.") ||
                              " "
                            }
                            inputProps={{ "data-lpignore": true }}
                            label="Keyword"
                            placeholder="Keyword"
                            type="text"
                            variant="outlined"
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={2} mb={1}>
                      <Typography fontWeight={400}>
                        {modalCopy.autoResponseDescription}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <MessageInputFormRow
                      attachmentField={values?.attachments ?? []}
                      attachmentFieldName="attachments"
                      hideUserPersonalization
                      currentAccount={currentAccount}
                      errors={errors}
                      fieldName="autoResponse"
                      fromKeyword
                      isCampaignProMember
                      isDisabled={false}
                      isSignatureActive={isSignatureActive}
                      label="Auto Response Message"
                      maxChars={maxCharacterLength}
                      SavedRepliesOption={SavedRepliesOption}
                      setFieldValue={setFieldValue}
                      setShortenedLink={setShortenedLink}
                      shortenedLink={shortenedLink}
                      signature={signature}
                      toggleSignature={handleToggleSignature}
                      touched={touched}
                      values={values}
                      withSignature
                    />
                    <AutomaticMessageIndicator />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogFooter
                onCancel={handleClose}
                isConfirmDisabled={!dirty || !isValid || isSubmitting}
                confirmText={modalCopy.callToAction}
              />
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default KeywordModal;
