import { NavLink } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import classNames from "classnames";

import { Box, Checkbox, IconButton, Tooltip, Typography } from "@mui/material";
import UnreadIcon from "./UnreadIcon";
import {
  getMessageBackgroundColor,
  getMessageBorderColor,
} from "./utils/getMessageColors";
import SCHEDULED_FILTERS from "features/Inbox/constants/scheduledFilters";
import getMomentWithTimezone from "utils/getMomentWithTimezone";
import conversationDisplayProperties from "utils/conversationDisplayProperties";
import getLatestPreviewableItemPreview from "utils/getLatestPreviewableItemPreview";
import H4 from "components/H4";
import H5 from "components/H5";
import Avatar from "components/Avatar";
import {
  AttachFileIcon,
  DoneIcon,
  ErrorIcon,
  ScheduleIcon,
  StarIcon,
  StarBorderIcon,
  UndoIcon,
} from "icons";
import { ContactSyncIcon } from "features/ContactSync/ContactSyncIcon";

const ContentSection = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
`;

const SelectSection = styled.div`
  flex: 0 0 auto;
  margin-right: 5px;
`;

const NameSection = styled.div`
  align-items: left;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: space-between;
  min-width: 0;

  ${H4}, ${H5} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StateSection = styled.div`
  align-items: center;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  margin-left: 5px;
`;

const LatestMessageAt = styled.div`
  color: ${(props) => {
    return props.theme.colors.text.secondary;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.secondaryFontSize;
  }};
  font-weight: 500;

  svg {
    font-size: 0.8rem;
    margin-right: 5px;
  }
`;

const AvatarWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 5px;
  min-height: 28px;
  min-width: 36px;
`;

const MessageBody = styled.div`
  &.withAttachments {
    margin-left: 10px;
  }

  p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-text-overflow: ellipsis;
    display: -webkit-box;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconWrapper = styled.div`
  align-self: flex-start;
  flex: 0 0 auto;
  margin-top: -10px;
  margin-right: 4px;
  :last-of-type {
    margin-right: -10px;
  }

  svg {
    fill: ${(props) => {
      return props.starred ? props.theme.colors.star : null;
    }};
  }
`;

const StyledCheckbox = styled(Checkbox)``;

const Conversation = styled(NavLink)`
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  border-left: 3px solid transparent;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  overflow: hidden;
  padding: 15px 15px 12px 5px;
  position: relative;
  text-decoration: none;

  button {
    opacity: 0.6;
  }

  ${IconWrapper} {
    display: none;
  }

  ${ContentSection}:first-child {
    margin-bottom: 10px;
  }

  &.isHidden {
    border: none;
    height: 0px;
    opacity: 0;
    padding: 0;
    transform: translateX(-100%);
    transition: all 300ms 0ms, height 300ms 300ms, background 0ms;
    visibility: hidden;
  }

  ${NameSection} *, ${MessageBody} p, ${StateSection} * {
    color: ${(props) => {
      return props.theme.colors.text.primary;
    }};
    font-weight 400;
  }

  &.unread {

    background: ${(props) => {
      return getMessageBackgroundColor(props);
    }};
    border-left: 3px solid ${(props) => {
      return getMessageBorderColor(props);
    }};
    border-bottom: 1px solid ${(props) => {
      return props.theme.colors.message.unreadBottomBorderColor;
    }};

    ${NameSection} *, ${MessageBody} p, ${StateSection} * {
      color: ${(props) => {
        return props.theme.colors.message.unreadMessageTextColor;
      }};
    }

    ${MessageBody} * {
      font-weight 500;
    }
    ${NameSection} * {
      font-weight 600;
    }

  }

  @media (pointer: fine) {
    &:hover {
      background: ${(props) => {
        return props.theme.colors.message.hoverColor;
      }};

      button {
        opacity: 1;
      }

      &.unread {
        button {
          color: ${(props) => {
            return props.theme.colors.message.unreadMessageButtonColor;
          }};
        }
      }

      ${StateSection} {
        display: none;
      }

      ${IconWrapper} {
        display: inline-block;
      }
    }
  }

  &.active {
    background: ${(props) => {
      return props.theme.colors.message.outboundMessage;
    }};
    border-left: 3px solid ${(props) => {
      return props.primaryColor;
    }};
  }
`;

const MessagePreview = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-size: ${(props) => {
    return props.theme.fonts.primaryFontSize;
  }};
  justify-content: flex-start;
  line-height: 1.25;
  min-width: 0;
  padding: 0;
  position: relative;
`;

const StyledErrorBase = styled.div`
  background: white;
  position: relative;
  height: 17px;
  width: 17px;
  margin-right: 5px;
  margin-left: 6px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  position: relative;
  left: 0;
  right: 0;
  margin-left: 0px;
  color: ${(props) => {
    return props.theme.colors.error.main;
  }};
`;

const inScheduledFilter = (filter) => {
  return SCHEDULED_FILTERS.includes(filter.slug);
};

export default function Root({
  closeConversationRequest,
  reopenConversationRequest,
  starConversationRequest,
  unstarConversationRequest,
  selectedRecords,
  setSelected,
  setSelectedRecords,
  conversation,
  conversationFilter,
  currentAccount,
  isSinglePanel,
  selectedConversations,
}) {
  const handleConversationSelection = (updatedConversation) => {
    return (event) => {
      event.stopPropagation();
      const updatedSelection = selectedConversations.includes(
        updatedConversation.id,
      )
        ? selectedConversations.filter((id) => {
            return id !== updatedConversation.id;
          })
        : [...selectedConversations, updatedConversation.id];
      setSelected(updatedSelection);

      const updatedSelectedRecords = selectedRecords.some((record) => {
        return record?.id === updatedConversation.assignedContact.id;
      })
        ? selectedRecords.filter((record) => {
            return (
              record !== null &&
              record.id !== updatedConversation.assignedContact.id
            );
          })
        : [...selectedRecords, updatedConversation.assignedContact];
      setSelectedRecords(updatedSelectedRecords);
    };
  };

  const handleUpdateState = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (conversation.currentState === "open") {
      return closeConversationRequest(`${conversation.id}/close`);
    }
    return reopenConversationRequest(`${conversation.id}/reopen`);
  };

  const handleUpdateStar = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { requestingUserInfo } = conversation;
    if (requestingUserInfo.starred) {
      return unstarConversationRequest(`${conversation.id}/star`);
    }
    return starConversationRequest(`${conversation.id}/star`);
  };

  const shouldHide = () => {
    const { requestingUserInfo } = conversation;
    switch (conversationFilter.slug) {
      case "you":
        return (
          !requestingUserInfo.assigned || conversation.currentState === "closed"
        );
      case "unassigned":
        return (
          requestingUserInfo.assigned || conversation.currentState === "closed"
        );
      case "starred":
        return !requestingUserInfo.starred;
      case "closed":
        return conversation.currentState === "open";
      case "search":
        return false;
      case "my_scheduled":
        return false;
      case "other_scheduled":
        return false;
      default:
        return conversation.currentState === "closed";
    }
  };

  const getPreviewMessage = () => {
    const { latestPreviewableItem, nextScheduledMessage } = conversation;
    const previewItem = inScheduledFilter(conversationFilter)
      ? nextScheduledMessage
      : latestPreviewableItem;
    return previewItem || nextScheduledMessage || {};
  };

  const {
    assignedContact,
    nextScheduledMessage,
    requestingUserInfo,
    unanswered,
  } = conversation;

  const previewMessage = getPreviewMessage();
  const attachments = get(previewMessage, ["attachments", "members"], []);
  const { name, number } = conversationDisplayProperties(conversation);

  const previewMessageScheduled =
    previewMessage.id &&
    previewMessage.id === get(nextScheduledMessage, ["id"]);

  const previewMessageAt = (() => {
    if (!previewMessage.timelinePosition) return "";
    if (previewMessageScheduled)
      return getMomentWithTimezone(previewMessage.timelinePosition).format(
        "L hh:mm:ss a z",
      );
    return moment(previewMessage.timelinePosition).fromNow(true);
  })();

  const isHidden = shouldHide();

  const messageFailed =
    previewMessage.errorCreating ||
    ["failed", "failed_transient", "unknown"].includes(
      previewMessage.deliveryState,
    );

  const messageStatus = (() => {
    const ageInMinutes = moment(Date.now()).diff(
      moment(previewMessage.timelinePosition),
      "minutes",
      true,
    );

    if (ageInMinutes < 5) return "new";
    if (ageInMinutes >= 5 && ageInMinutes < 10) return "stale";
    return "superStale";
  })();

  return (
    <Conversation
      data-testid="conversation"
      key={conversation.id}
      status={messageStatus}
      className={classNames({
        isSinglePanel,
        isHidden,
        unanswered,
        unread: !requestingUserInfo.read,
      })}
      to={{
        pathname: `/${currentAccount.slug}/inbox/${decodeURIComponent(
          conversationFilter.slug,
        )}/${conversation.slug}`,
        search: document.location.search,
      }}
    >
      <ContentSection>
        <SelectSection>
          <StyledCheckbox
            color="secondary"
            checked={selectedConversations.includes(conversation.id)}
            onClick={handleConversationSelection(conversation)}
            inputProps={{
              "aria-label": `select conversation for ${name}`,
              "data-testid": `select-conversation-for-${name}`,
            }}
          />
        </SelectSection>
        <NameSection>
          <Box
            display="flex"
            flexDirection="row"
            id="partnerLogo"
            alignItems="center"
            aria-label="name-section"
            data-testid="name-section"
          >
            <H4>{name || number}</H4>
            <ContactSyncIcon contact={assignedContact} />
          </Box>
          <H5 data-testid="phone-number">{name && number}</H5>
        </NameSection>
        <StateSection>
          {!requestingUserInfo.read && <UnreadIcon status={messageStatus} />}
          <LatestMessageAt>
            {previewMessageScheduled && <ScheduleIcon />}
            <span>{previewMessageAt}</span>
          </LatestMessageAt>
        </StateSection>
        <Box
          alignSelf="flex-start"
          flex="0 0 auto"
          marginTop="-10px"
          marginRight="4px"
          sx={(theme) => {
            return {
              ":last-of-type": { marginRight: "-10px" },
              svg: {
                fill: requestingUserInfo.starred
                  ? theme.palette.customColors.star
                  : null,
              },
            };
          }}
        >
          <IconButton onClick={handleUpdateStar} p={2} size="large">
            {requestingUserInfo.starred ? (
              <Tooltip title="Unstar Conversation">
                <StarIcon data-testid="unstar-conversation" fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="Star Conversation">
                <StarBorderIcon
                  data-testid="star-conversation"
                  fontSize="small"
                />
              </Tooltip>
            )}
          </IconButton>
        </Box>
        <IconWrapper>
          <IconButton onClick={handleUpdateState} p={2} size="large">
            {conversation.currentState === "open" ? (
              <Tooltip title="Close Conversation">
                <DoneIcon data-testid="close-conversation" fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip
                title="Open Conversation"
                data-testid="open-conversation"
              >
                <UndoIcon fontSize="small" />
              </Tooltip>
            )}
          </IconButton>
        </IconWrapper>
      </ContentSection>
      <ContentSection>
        <AvatarWrapper>
          {previewMessage && previewMessage.direction === "out" && (
            <Avatar
              subject={previewMessage.sender}
              size={24}
              tooltip={previewMessage.sender.name}
            />
          )}
        </AvatarWrapper>
        <MessagePreview data-testid="message-preview">
          {messageFailed && (
            <StyledErrorBase>
              <StyledErrorIcon />
            </StyledErrorBase>
          )}
          {attachments?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "action.active",
                maxWidth: "100%",
                pb: "0.25rem",
              }}
            >
              <AttachFileIcon fontSize="small" />
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                color="text.secondary"
                variant="caption"
              >
                {attachments[0].originalFilename}
              </Typography>
            </Box>
          )}
          <MessageBody
            className={attachments?.length ? "withAttachments" : null}
          >
            <p>
              {messageFailed
                ? "Message Not Delivered"
                : getLatestPreviewableItemPreview(previewMessage)}
            </p>
          </MessageBody>
        </MessagePreview>
      </ContentSection>
    </Conversation>
  );
}

Root.propTypes = {
  closeConversationRequest: PropTypes.func.isRequired, // withRecord
  conversation: PropTypes.object.isRequired,
  conversationFilter: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired, // withConnect
  isSinglePanel: PropTypes.bool.isRequired, // withSizes
  reopenConversationRequest: PropTypes.func.isRequired, // withRecord
  selectedConversations: PropTypes.array.isRequired,
  selectedRecords: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  setSelectedRecords: PropTypes.func.isRequired,
  starConversationRequest: PropTypes.func.isRequired, // withRecord
  unstarConversationRequest: PropTypes.func.isRequired, // withRecord
};
