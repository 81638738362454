import { compose } from "redux";
import { withRouter } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import withSizes from "react-sizes";

import Root from "components/Table/components/Root";

/**
 * This is the last remaining instance of withStyles in the codebase.
 * It is used to style the "components/Table" component,
 * which we are replacing with DataGridPro.
 *  */

const afterJss = {
  boxShadow: "5px 0 10px -10px",
  content: "''",
  height: "calc(100% + 20px)",
  position: "absolute",
  top: "-10px",
  right: 0,
  width: "24px",
  zIndex: 4,
};

const styles = (muiTheme) => {
  return {
    root: {
      width: "100%",
      marginTop: muiTheme.spacing(3),
      overflowX: "auto",
      overflowY: "auto",
    },
    head: {
      backgroundColor: muiTheme.palette.background.paper,
      borderBottom: "none",
      height: "55px",
      position: "sticky",
      top: 0,
      zIndex: 2,
      whiteSpace: "nowrap",
      [muiTheme.breakpoints.down("md")]: {
        paddingLeft: "12px",
        paddingRight: "12px",
      },
      "&::before": {
        borderBottom: `1px solid ${muiTheme.palette.divider}`,
        bottom: "-1px",
        content: "''",
        height: "1px",
        left: 0,
        position: "absolute",
        width: "100%",
        zIndex: 4,
      },
    },
    headCheckbox: {
      left: 0,
      padding: "0 12px",
      minWidth: "64px",
      width: "64px",
      zIndex: 3,
    },
    headPrimary: {
      zIndex: 3,
      left: 0,
      [muiTheme.breakpoints.down("md")]: {
        paddingLeft: "12px",
        paddingRight: "12px",
      },
    },
    headWithDivider: {
      "&::after": afterJss,
    },
    cell: {
      borderBottom: "none",
      paddingTop: "4px",
      paddingBottom: "4px",
      [muiTheme.breakpoints.down("md")]: {
        paddingLeft: "12px",
        paddingRight: "12px",
      },
    },
    cellCheckbox: {
      backgroundColor: muiTheme.palette.background.paper,
      borderBottom: "none",
      padding: "0 12px",
      position: "sticky",
      left: 0,
      width: "64px",
      zIndex: 1,
    },
    cellNowrap: {
      whiteSpace: "nowrap",
    },
    cellPrimary: {
      backgroundColor: muiTheme.palette.background.paper,
      borderBottom: "none",
      position: "sticky",
      zIndex: 1,
      left: 0,
      [muiTheme.breakpoints.down("md")]: {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    cellWithDivider: {
      "&::after": afterJss,
    },
    fab: {
      bottom: "calc(56px + 1rem)",
      zIndex: 5,
    },
    icon: {
      transform: "rotate(90deg)",
    },
  };
};

/**
 * @deprecated
 * - Replace with MUI DataGridPro or TextUsTable
 */
export default compose(
  withSizes(({ width }) => {
    return {
      showExpandButton: width < 600,
    };
  }),
  withStyles(styles),
  withRouter,
)(Root);
