import { Box, Typography, Button, useTheme } from "@mui/material";
import { useState } from "react";
import { SelectRecipientsProps } from "../types";
import { CampaignHeader } from "../CampaignHeader";
import { StepSection } from "./StepSection";
import { ClickToUploadSelectGroup } from "./ClickToUploadSelectGroup";
import { ClickToUploadSelectGroupResponsive } from "./ClickToUploadSelectGroupResponsive";

export function SelectRecipients({
  campaignType,
  campaignsResponsiveness,
  contactImport,
  setSelectGroup,
}: SelectRecipientsProps) {
  // ==== FIELDS ==== //
  const responsiveCopy = {
    oneTime:
      "Upload a spreadsheet, select a group, or select contacts that you would like to receive your campaign. At a minimum the spreadsheet must contain a contact name and phone number.",
  };
  const oneTimeCampaign = campaignType === "single";
  const selectRecipientsText = oneTimeCampaign
    ? campaignsResponsiveness
      ? responsiveCopy.oneTime
      : `Please upload a spreadsheet, select a group, or select contacts
    that you would like to receive your campaign. At a minimum the
    spreadsheet must contain a contact name and phone number.`
    : "Please select the group that you would like to receive your campaign.";

  // ==== HOOKS ==== //
  const theme = useTheme();
  const [fileUploaded, setFileUploaded] = useState(false);

  // ==== RENDER ==== //
  return (
    <StepSection campaignsResponsiveness={campaignsResponsiveness}>
      <CampaignHeader
        title="Select recipients"
        subtitle={selectRecipientsText}
      />

      {campaignsResponsiveness ? (
        <ClickToUploadSelectGroupResponsive
          contactImport={contactImport}
          oneTimeCampaign={oneTimeCampaign}
          mode={theme.palette.mode}
          setFileUploaded={setFileUploaded}
          setSelectGroup={setSelectGroup}
        />
      ) : (
        <ClickToUploadSelectGroup
          contactImport={contactImport}
          oneTimeCampaign={oneTimeCampaign}
          setFileUploaded={setFileUploaded}
          setSelectGroup={setSelectGroup}
        />
      )}
    </StepSection>
  );
}
