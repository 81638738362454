/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash/get";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { createSnackbarContent } from "components/Snackbar";

import {
  ArchiveFilledIcon,
  CloudDownloadIcon,
  DraftsIcon,
  MarkunreadIcon,
  MoreVertIcon,
  StarIcon,
  StarBorderIcon,
  UnarchiveOutlinedIcon,
} from "icons";

import getUserRole from "utils/getUserRole";

const UPDATE_OPTIONS = [
  {
    key: "close",
    title: "Close conversation",
    successMessage: "Conversation closed.",
    icon: <ArchiveFilledIcon />,
    getUrl: (conversationId) => {
      return `${conversationId}/close`;
    },
  },
  {
    key: "reopen",
    title: "Reopen conversation",
    successMessage: "Conversation reopened.",
    icon: <UnarchiveOutlinedIcon />,
    getUrl: (conversationId) => {
      return `${conversationId}/reopen`;
    },
  },
  {
    key: "star",
    title: "Star",
    successMessage: "Conversation starred.",
    icon: <StarIcon />,
    getUrl: (conversationId) => {
      return `${conversationId}/star`;
    },
  },
  {
    key: "unstar",
    title: "Remove star",
    successMessage: "Conversation unstarred.",
    icon: <StarBorderIcon />,
    getUrl: (conversationId) => {
      return `${conversationId}/star`;
    },
  },
  {
    key: "read",
    title: "Mark read",
    successMessage: "Conversation marked read.",
    icon: <DraftsIcon />,
    getUrl: (conversationId) => {
      return `${conversationId}/read`;
    },
  },
  {
    key: "unread",
    title: "Mark unread",
    successMessage: "Conversation marked unread.",
    icon: <MarkunreadIcon />,
    getUrl: (conversationId) => {
      return `${conversationId}/read`;
    },
  },
  {
    key: "export",
    title: "Export",
    successMessage:
      "Data export successful! Data will be emailed to you shortly.",
    icon: <CloudDownloadIcon />,
    getUrl: (conversationId) => {
      return `${conversationId}/export`;
    },
  },
];

function ConversationUpdateOption(props) {
  const {
    conversation,
    currentAccount,
    currentUser,
    disabled,
    readConversationRequest,
    collapseIconMenu,
  } = props;

  const smallScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("xs");
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const getOptions = () => {
    return UPDATE_OPTIONS.filter(({ key }) => {
      return conversation.requestingUserInfo.starred
        ? key !== "star"
        : key !== "unstar";
    })
      .filter(({ key }) => {
        return conversation.currentState === "open"
          ? key !== "reopen"
          : key !== "close";
      })
      .filter(({ key }) => {
        return conversation.requestingUserInfo.read
          ? key !== "read"
          : key !== "unread";
      })
      .filter(({ key }) => {
        return (
          key !== "export" ||
          (get(currentAccount, [
            "settings",
            "conversationExportsEnabled",
            "value",
          ]) &&
            getUserRole(currentUser, currentAccount) === "admin")
        );
      });
  };

  const successCallback = (message) => {
    enqueueSnackbar(`${message}`, {
      content: createSnackbarContent("info", "auto"),
    });
  };

  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (mobileScreen) {
      setAnchorEl(null);
    }
  }, [mobileScreen]);

  const handleUpdateOptionClick = ({ key, getUrl, successMessage }) => {
    return () => {
      handleOptionsClose();
      if (key === "read") {
        readConversationRequest(
          getUrl(conversation.id),
          {
            up_to: moment().toISOString(),
          },
          { successCallback: successCallback(successMessage) },
        );
      } else {
        props[`${key}ConversationRequest`](getUrl(conversation.id), null, {
          successCallback: successCallback(successMessage),
        });
      }
    };
  };

  const options = getOptions();

  const responsiveOptions =
    !smallScreen && conversation.currentState !== "closed"
      ? options.slice(1)
      : options;

  return conversation.currentState !== "new" ? (
    <>
      <Tooltip title="Conversation options" disableFocusListener>
        <IconButton
          disabled={disabled === true}
          aria-haspopup="true"
          onClick={handleOptionsClick}
          data-product-tour="ConversationOption-Update"
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        disableAutoFocusItem
        getContentAnchorEl={null}
        MenuListProps={{ disablePadding: false }}
        onClose={handleOptionsClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {collapseIconMenu &&
        conversation.currentState === "open" &&
        !smallScreen ? (
          <MenuItem
            onClick={handleUpdateOptionClick({
              key: "close",
              getUrl: (conversationId) => {
                return `${conversationId}/close`;
              },
              successMessage: "Conversation closed.",
            })}
          >
            {" "}
            <ListItemIcon>
              {" "}
              <ArchiveFilledIcon />{" "}
            </ListItemIcon>
            <ListItemText> Close conversation </ListItemText>{" "}
          </MenuItem>
        ) : null}
        {responsiveOptions.map((option) => {
          return (
            <MenuItem
              key={option.title}
              onClick={handleUpdateOptionClick(option)}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.title}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  ) : null;
}

export default ConversationUpdateOption;

ConversationUpdateOption.propTypes = {
  addNotification: PropTypes.func.isRequired,
  closeConversationRequest: PropTypes.func.isRequired,
  conversation: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  readConversationRequest: PropTypes.func.isRequired,
  reopenConversationRequest: PropTypes.func.isRequired,
  starConversationRequest: PropTypes.func.isRequired,
  unreadConversationRequest: PropTypes.func.isRequired,
  unstarConversationRequest: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  collapseIconMenu: PropTypes.bool,
};
