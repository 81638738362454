import { Typography, useMediaQuery, Theme, Box } from "@mui/material";
import { startCase } from "lodash";
import { CampaignHeaderProps } from "./types";
import { useCurrentAccount } from "hooks";

export function CampaignHeader({ title, subtitle }: CampaignHeaderProps) {
  // ==== HOOKS ==== //
  const mobileView = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });
  const currentAccount = useCurrentAccount();

  // ==== FIELDS ==== //
  const { campaignsResponsiveness = false } = currentAccount.featureFlags;

  // ==== RENDER ==== //
  if (campaignsResponsiveness) {
    return (
      <Box
        className="campaign-header"
        margin="0 auto 24px auto"
        sx={{
          maxWidth: { xs: "288px", sm: "536px", md: "600px" },
          width: "100%",
        }}
      >
        <Typography
          data-testid="campaign-header-title"
          fontSize="24px"
          marginTop={mobileView ? "32px" : "0"}
          textAlign="center"
          variant="h4"
        >
          {title}
        </Typography>

        {subtitle && (
          <Typography
            color="textSecondary"
            data-testid="campaign-header-subtitle"
            marginTop="4px"
            textAlign="center"
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        align="center"
        component="h2"
        data-testid="text"
        style={{ fontSize: "2.5rem" }}
        variant="h2"
      >
        {startCase(title)}
      </Typography>

      {subtitle && (
        <Typography
          color="textSecondary"
          data-testid="campaign-header-subtitle"
          margin="1rem 0 2rem"
          textAlign="center"
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}
