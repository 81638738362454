/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import { ConnectedIntegrations } from "../ConnectedIntegrations/ConnectedIntegrations";
import { AvailableIntegrations } from "../AvailableIntegrations/AvailableIntegrations";
import { getAllIntegrations } from "../../api/getAllIntegrations";
import { IntegrationsLandingPageProps } from "./types";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import PageHeader from "components/Page/PageHeader";
import { IntegrationProvider } from "models/IntegrationProvider";

export function IntegrationsLandingPage({
  account,
  toggleNavbar,
}: IntegrationsLandingPageProps) {
  const [allIntegrations, setAllIntegrations] = useState<IntegrationProvider[]>(
    [],
  );
  const [connectedIntegrations, setConnectedIntegrations] = useState<
    IntegrationProvider[]
  >([]);
  const [selectedIntegration, setSelectedIntegration] =
    useState<IntegrationProvider | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleGetAllIntegrations = async () => {
    try {
      const response = await getAllIntegrations();
      if (!response.ok) {
        throw new Error("Cannot fetch available integrations");
      }
      const data = await response.json();
      setAllIntegrations(data);
    } catch {
      enqueueSnackbar("", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (!selectedIntegration && account) {
      handleGetAllIntegrations();
    }
  }, [account, selectedIntegration]);

  return (
    <SettingsPageWrapper>
      <PageHeader title="Integrations" toggleSidebar={toggleNavbar} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
          paddingY: "32px",
          paddingX: "96px",
        }}
      >
        {selectedIntegration?.id ? (
          <div>Placeholder for integration settings</div>
        ) : (
          <>
            <ConnectedIntegrations
              allIntegrations={allIntegrations}
              connectedIntegrations={connectedIntegrations}
              currentAccount={account}
              selectedIntegration={selectedIntegration}
              setConnectedIntegrations={setConnectedIntegrations}
              setSelectedIntegration={setSelectedIntegration}
            />
            <Divider
              sx={{
                marginBottom: "32px",
              }}
            />
            <AvailableIntegrations
              allIntegrations={allIntegrations}
              connectedIntegrations={connectedIntegrations}
              currentAccount={account}
              selectedIntegration={selectedIntegration}
              setSelectedIntegration={setSelectedIntegration}
            />
            <Divider
              sx={{
                marginBottom: "32px",
              }}
            />
          </>
        )}
      </Box>
    </SettingsPageWrapper>
  );
}
