import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

import Tooltip from "@mui/material/Tooltip";
import InfoIconOutlined from "@mui/icons-material/InfoOutlined";
import { CampaignHeader } from "./CampaignHeader";

const styles = { margin: ".25rem .5rem" };

function CampaignType({
  campaignType,
  setCampaignType,
  recurringDisabled = false,
  campaignsResponsiveness,
}) {
  // TODO: Remove campaignsResponsiveness flag
  return (
    <Box
      display="flex"
      flex="1 1 auto"
      alignItems="center"
      flexDirection="column"
      minHeight="0"
      p={campaignsResponsiveness ? "0rem" : "20px"}
      width="100%"
    >
      <CampaignHeader title="Choose campaign type" />

      <FormControl
        component="fieldset"
        style={{
          fontSize: "1.5rem",
          margin: campaignsResponsiveness ? "0rem" : "3rem auto",
          padding: "0rem",
          display: "flex",
          alignItems: "flex-start",
        }}
        variant="standard"
      >
        <RadioGroup
          aria-label="Campaign Type"
          name="campaignType"
          value={campaignType}
          onChange={({ target: { value } }) => {
            return setCampaignType(value);
          }}
        >
          <FormControlLabel
            aria-label="Single Campaign"
            value="single"
            control={
              <Radio
                color="secondary"
                data-testid="single-campaign-radio-button"
              />
            }
            label={
              <>
                {campaignsResponsiveness
                  ? "One-time campaign"
                  : "One Time Campaign"}
                <Tooltip
                  placement="right"
                  title="Send a campaign once to your chosen recipients."
                >
                  {campaignsResponsiveness ? (
                    <InfoIconOutlined
                      sx={(theme) => {
                        return {
                          color: theme.palette.text.secondary,
                          margin: ".25rem .5rem",
                        };
                      }}
                    />
                  ) : (
                    <InfoIconOutlined style={styles} />
                  )}
                </Tooltip>
              </>
            }
          />
          <FormControlLabel
            aria-label="Recurring Campaign"
            value="recurring"
            control={
              <Radio
                color="secondary"
                disabled={recurringDisabled}
                data-testid="recurring-campaign-radio-button"
              />
            }
            label={
              <>
                {campaignsResponsiveness
                  ? "Recurring campaign"
                  : "Recurring Campaign"}
                <Tooltip
                  placement="right"
                  title="Create a campaign to send to a group on a set cadence."
                >
                  {campaignsResponsiveness ? (
                    <InfoIconOutlined
                      sx={(theme) => {
                        return {
                          color: theme.palette.text.secondary,
                          margin: ".25rem .5rem",
                        };
                      }}
                    />
                  ) : (
                    <InfoIconOutlined style={styles} />
                  )}
                </Tooltip>
              </>
            }
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

CampaignType.propTypes = {
  campaignType: PropTypes.string.isRequired,
  recurringDisabled: PropTypes.bool,
  setCampaignType: PropTypes.func.isRequired,
  campaignsResponsiveness: PropTypes.bool,
};
export default CampaignType;
