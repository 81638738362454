import { Box, styled, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import { formatInTimeZone } from "date-fns-tz";

import { formatValues } from "../../SendCampaign/sharedComponents/constants/timeOptions";
import { CampaignMessageProps } from "./types";
import AttachmentPreview from "features/Campaigns/components/AttachmentPreview";
import Avatar from "components/Avatar";
import MessageBubbleTip from "components/ConversationThread/MessageBubbleTip";
import { useCurrentAccount, useTimeZones } from "hooks";
import { CampaignFilledIcon } from "icons";

export const Bubble = styled(Box)({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  transition: "all ease-in-out 0.3s",
});

export default function CampaignMessage({
  attachment,
  campaign,
  campaignsProUser,
}: CampaignMessageProps) {
  const { abbreviatedAccountTimeZone, accountTimeZone } = useTimeZones();

  const {
    featureFlags: { campaignsResponsiveness = false },
  } = useCurrentAccount();

  const { messageBody, scheduledAt, schedule, state, type, user } = campaign;

  const singleCampaign = type === "single";

  const { formattedFrequency, formattedSendDate, formattedTime } =
    formatValues(schedule);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minWidth="16.5rem"
      maxWidth="31.25rem"
    >
      <Box display="flex" justifyContent="flex-end" marginRight="2rem">
        <Bubble>
          <Box
            sx={(theme) => {
              return {
                background: theme.palette.messages.automation,
                borderRadius: "0.75rem",
                maxWidth: "25rem",
                padding: "0.5rem 1rem",
                whiteSpace: "break-spaces",
                overflow: "hidden",
              };
            }}
          >
            {!!attachment && <AttachmentPreview attachment={attachment} />}
            <Typography
              variant="body1"
              marginTop={0}
              sx={{
                "&:last-child": {
                  marginBottom: 0,
                },
              }}
            >
              {messageBody}
            </Typography>
          </Box>
          <Box marginLeft="-10px" minWidth="15px">
            <MessageBubbleTip direction="out" messageSource="campaigns" />
          </Box>
        </Bubble>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
        gap="0.25rem"
      >
        {state && singleCampaign ? (
          <Typography variant="caption" color="text.secondary">
            {capitalize(state)}
          </Typography>
        ) : null}
        {scheduledAt ? (
          <Typography variant="caption" color="text.secondary">
            {formatInTimeZone(
              scheduledAt,
              accountTimeZone,
              "MM/dd/yyyy hh:mm a z",
            )}
          </Typography>
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            gap="0.25rem"
            alignItems="center"
          >
            {schedule && !singleCampaign && !campaignsResponsiveness ? (
              <Typography variant="caption" color="text.secondary">
                Sending {formattedFrequency} on {formattedSendDate} at{" "}
                {formattedTime} {abbreviatedAccountTimeZone}
              </Typography>
            ) : (
              <Typography variant="caption" color="text.secondary">
                <CampaignFilledIcon
                  sx={{
                    color: "text.secondary",
                    height: "1rem",
                    width: "1rem",
                  }}
                />{" "}
                Campaign
              </Typography>
            )}
          </Box>
        )}
        <Avatar
          subject={campaignsProUser || user}
          tooltip={
            campaignsProUser ||
            (typeof user === "string" || !user ? "" : user.name)
          }
          sx={{
            marginLeft: "0.25rem",
          }}
        />
      </Box>
    </Box>
  );
}
