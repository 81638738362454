import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AccordionDetails from "@mui/material/AccordionDetails";
import ContactCard from "./components/ContactCard";
import ContactNotes from "./containers/ContactNotes";
import ContactTags from "./containers/ContactTags";
import ConversationCollection from "./containers/ConversationCollection";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import ExpansionPanelDetails from "components/ExpansionPanel/Details";

import Logo from "components/Logo";
import { BullhornCandidateDetails } from "features/ContactSync";
import { GreenhouseCandidateDetails } from "features/ContactSync";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const CardWrapper = styled.div`
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  flex: 0 0 auto;
`;

const AdditionalDetails = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

class ContactSidebar extends Component {
  static propTypes = {
    activeConversationId: PropTypes.string,
    conversation: PropTypes.object,
    closeModal: PropTypes.func,
    contact: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    expanded: PropTypes.string,
    handleConversationClick: PropTypes.func.isRequired,
    handleEditClick: PropTypes.func,
    setActiveView: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded,
      yourConversationsCount: undefined,
      otherConversationsCount: undefined,
    };
  }

  getAccountConversationCollectionId = () => {
    const { contact, currentAccount } = this.props;
    const conversationCollectionId =
      typeof contact.conversations === "string"
        ? contact.conversations
        : contact.conversations.id;
    return `${conversationCollectionId}?account=${currentAccount.id}`;
  };

  getOtherConversationCollectionId = () => {
    const { contact, currentAccount } = this.props;
    const conversationCollectionId =
      typeof contact.conversations === "string"
        ? contact.conversations
        : contact.conversations.id;
    return `${conversationCollectionId}?excludeAccount=${currentAccount.id}`;
  };

  setConversationCount = (key) => {
    return (count) => {
      this.setState({ [key]: count });
    };
  };

  handleChange = (panel) => {
    return (event, expanded) => {
      this.setState({
        expanded: expanded ? panel : undefined,
      });
    };
  };

  render() {
    const {
      activeConversationId,
      conversation,
      closeModal,
      contact,
      currentAccount,
      handleConversationClick,
      handleEditClick,
      setActiveView,
    } = this.props;
    const { expanded, yourConversationsCount, otherConversationsCount } =
      this.state;
    const tags = get(contact, ["data", "tags"], []) || [];
    const messagingEnabled = currentAccount.phoneNumbers.length > 0;

    return (
      <Wrapper>
        <CardWrapper>
          <ContactCard
            contact={contact}
            conversation={conversation}
            currentAccount={currentAccount}
            handleConversationClick={handleConversationClick}
            handleEditClick={handleEditClick}
            closeModal={closeModal}
          />
        </CardWrapper>
        <AdditionalDetails>
          {contact?.data?.bullhorn?.Candidate ? (
            <ExpansionPanel
              square
              expanded={expanded === "bullhorn details"}
              onChange={this.handleChange("bullhorn details")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Bullhorn details
              </ExpansionPanelSummary>
              <AccordionDetails
                sx={(theme) => {
                  return {
                    padding: 2,
                    background:
                      theme.palette.customColors.backgrounds.secondary,
                  };
                }}
              >
                <BullhornCandidateDetails
                  candidate={contact.data.bullhorn.Candidate}
                />
              </AccordionDetails>
            </ExpansionPanel>
          ) : null}
          {contact?.data?.greenhouse?.Candidate &&
          //TODO: Remove greenhouseV3 feature flag once enabled
          currentAccount.featureFlags?.greenhouseV3 ? (
            <ExpansionPanel
              square
              expanded={expanded === "greenhouse details"}
              onChange={this.handleChange("greenhouse details")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Greenhouse details
              </ExpansionPanelSummary>
              <AccordionDetails
                sx={(theme) => {
                  return {
                    padding: 2,
                    background:
                      theme.palette.customColors.backgrounds.secondary,
                  };
                }}
              >
                <GreenhouseCandidateDetails
                  candidate={contact.data.greenhouse.Candidate}
                  setActiveView={setActiveView}
                />
              </AccordionDetails>
            </ExpansionPanel>
          ) : null}
          <ExpansionPanel
            square
            expanded={expanded === "tags"}
            onChange={this.handleChange("tags")}
          >
            <ExpansionPanelSummary
              data-testid="tags"
              expandIcon={<ExpandMoreIcon />}
            >
              {`Tags (${tags.length})`}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ContactTags
                account={currentAccount}
                closeModal={closeModal}
                contact={contact}
                contactTagsId={`/${currentAccount.slug}/contacts/tags`}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === "notes"}
            onChange={this.handleChange("notes")}
          >
            <ExpansionPanelSummary
              data-testid="notes"
              expandIcon={<ExpandMoreIcon />}
            >
              {`Notes${
                typeof contact.notes === "string"
                  ? " (0)"
                  : ` (${contact.notes.totalItems})`
              }`}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ContactNotes
                contactNoteCollectionId={
                  typeof contact.notes === "string"
                    ? contact.notes
                    : contact.notes.id
                }
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {messagingEnabled && (
            <ExpansionPanel
              square
              expanded={expanded === "yourConversations"}
              onChange={this.handleChange("yourConversations")}
            >
              <ExpansionPanelSummary
                expandIcon={
                  yourConversationsCount >= 0 ? (
                    <ExpandMoreIcon />
                  ) : (
                    <Logo
                      animate
                      width={20}
                      color="transparent"
                      dotColor="primary"
                    />
                  )
                }
              >
                {`Your conversations${
                  yourConversationsCount ? ` (${yourConversationsCount})` : ""
                }`}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ConversationCollection
                  activeConversationId={activeConversationId}
                  conversationCollectionType="yourConversations"
                  conversationCollectionId={this.getAccountConversationCollectionId()}
                  setActiveConversation={handleConversationClick}
                  setConversationCount={this.setConversationCount(
                    "yourConversationsCount",
                  )}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          <ExpansionPanel
            data-testid="other-conversations"
            square
            expanded={expanded === "otherConversations"}
            onChange={this.handleChange("otherConversations")}
          >
            <ExpansionPanelSummary
              data-testid="other-conversations-summary"
              expandIcon={
                otherConversationsCount >= 0 ? (
                  <ExpandMoreIcon />
                ) : (
                  <Logo
                    animate
                    width={20}
                    color="transparent"
                    dotColor="primary"
                  />
                )
              }
            >
              {`Other conversations${
                otherConversationsCount ? ` (${otherConversationsCount})` : ""
              }`}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ConversationCollection
                data-testid={this.getOtherConversationCollectionId()}
                activeConversationId={activeConversationId}
                conversationCollectionType="otherConversations"
                conversationCollectionId={this.getOtherConversationCollectionId()}
                setActiveConversation={handleConversationClick}
                setConversationCount={this.setConversationCount(
                  "otherConversationsCount",
                )}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </AdditionalDetails>
      </Wrapper>
    );
  }
}

export default ContactSidebar;
