/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import { Formik, Field, Form } from "formik";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  createSavedReply,
  fetchSavedReplyCollection,
  updateSavedReply,
} from "features/SavedReplies/api";
import { SavedReplyValidationSchema } from "formHelpers/validationSchemas";
import getUserRole from "utils/getUserRole";
import MessageInputFormRow from "components/MessageInputFormRow";
import { Snackbar } from "components/Snackbar";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";
import { swapLinksInBody } from "components/MessageInputOptions/ShortenedLinkOption/utils/swapLinksInBody";

// Prevent Chrome from transitioning to a yellow background on autocomplete
const FormWrapper = styled.div`
  font-size: 0.8rem;
  padding: 20px;
  width: 50%;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
  }

  form {
    display: flex;
    flex-flow: column;
    height: 100%;
    max-height: 500px;
    justify-content: center;
  }
`;

function SavedReplyForm({
  currentAccount,
  currentUser,
  fetchSavedReplyCollectionRequest,
  handleCloseModal,
  handleSavedReplySelected,
  savedReplyCollectionId,
  savedReply,
  setActiveSavedReply,
  setSavedReplies,
  settings,
  settingsAccount,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [submitType, setSubmitType] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: currentAccount.shortenedLinkPreviewUrl,
  });

  const getIsShareable = (isDisabled) => {
    const account = settingsAccount || currentAccount;
    return (
      !isDisabled &&
      getUserRole(currentUser, account) === "admin" &&
      (account.multiUser || account.childrenCount > 0)
    );
  };

  const handleSubmit = async (values, actions) => {
    const { shortLink, fullLink } = shortenedLink;
    const { content } = values;
    const templateBody = fullLink
      ? swapLinksInBody(shortLink, `{{ "${fullLink}" | shortlink }}`, content)
      : content;
    const { requestUrl, submitHandler } = savedReply
      ? { requestUrl: savedReply.id, submitHandler: updateSavedReply }
      : {
          requestUrl: savedReplyCollectionId,
          submitHandler: createSavedReply,
        };
    const templateParams = { ...values, content: templateBody };

    try {
      const response = await submitHandler(requestUrl, templateParams);
      const data = await response.json();

      if (response.ok) {
        actions.setSubmitting(false);
        enqueueSnackbar(
          savedReply
            ? "You successfully edited a template."
            : "You successfully created a template.",
          {
            content: (key, message) => {
              return <Snackbar id={key} message={message} variant="info" />;
            },
          },
        );
        const flaggedFetch = () => {
          fetchSavedReplyCollection(currentUser.savedReplies, "mine")
            .then(async (res) => {
              const newSavedReplies = await res.json();
              return setSavedReplies(newSavedReplies);
            })
            .catch((error) => {
              console.error(error);
            });
        };
        flaggedFetch();
      }

      if (handleCloseModal) {
        handleCloseModal();
      }

      if (submitType === "saveAndUse") {
        return handleSavedReplySelected(data);
      }
      return setActiveSavedReply({ ...data, user: currentUser });
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors(error);
      enqueueSnackbar("Something went wrong. Please try again.", {
        content: (key, message) => {
          return <Snackbar id={key} message={message} variant="error" />;
        },
      });
    }
    return undefined;
  };

  const { conversationMaxMessageLength } = currentAccount.settings;
  const maxCharacterLength =
    conversationMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;
  const initialValues = {
    title: get(savedReply, ["title"], ""),
    content: get(savedReply, ["content"], ""),
    shared: get(savedReply, ["shared"], false),
  };
  const savedReplyUserId = savedReply?.user;

  const isDisabled = savedReply && savedReplyUserId !== currentUser.id;
  const isShareable = getIsShareable(isDisabled);

  useEffect(() => {
    setShortenedLink((prevState) => {
      return {
        ...prevState,
        fullLink: "",
      };
    });
  }, [savedReply]);

  useEffect(() => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 100);
  }, [savedReply]);

  return (
    <FormWrapper>
      {!showLoader && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          initialErrors={SavedReplyValidationSchema(
            maxCharacterLength,
          ).isValidSync(initialValues)}
          validationSchema={SavedReplyValidationSchema(maxCharacterLength)}
          onSubmit={handleSubmit}
        >
          {({
            errors = {},
            touched = {},
            isSubmitting,
            isValid,
            values,
            setFieldValue,
            submitForm,
          }) => {
            return (
              <Form>
                <Box display="flex" flex="0 0 auto" mb={2}>
                  <Field type="text" name="title">
                    {({ field }) => {
                      return (
                        <TextField
                          disabled={isDisabled}
                          {...field}
                          autoFocus
                          variant="outlined"
                          error={touched.title && Boolean(errors.title)}
                          fullWidth
                          helperText={
                            (touched.title && errors.title) ||
                            `${values.title.length} of 100 characters`
                          }
                          inputProps={{ "data-lpignore": true }}
                          label="Title"
                          placeholder="Best Template!"
                          data-testid="create-a-template-title"
                          type="text"
                        />
                      );
                    }}
                  </Field>
                </Box>
                <MessageInputFormRow
                  currentAccount={currentAccount}
                  errors={errors}
                  fieldName="content"
                  isDisabled={isDisabled}
                  label="Content"
                  maxChars={maxCharacterLength}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  setShortenedLink={setShortenedLink}
                  shortenedLink={shortenedLink}
                />
                {isShareable && (
                  <Box
                    color="text.secondary"
                    display="flex"
                    flex="0 0 auto"
                    mb={2}
                  >
                    <Field name="shared">
                      {({ field, form }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isDisabled}
                                {...field}
                                checked={field.value}
                                onChange={(event) => {
                                  const { checked } = event.target;
                                  form.setFieldValue(field.name, checked);
                                }}
                                color="secondary"
                              />
                            }
                            label="Share with account"
                          />
                        );
                      }}
                    </Field>
                  </Box>
                )}
                {!isDisabled && (
                  <Box
                    alignItems="center"
                    display="flex"
                    flex="0 0 auto"
                    justifyContent="flex-end"
                  >
                    <Box>
                      <Button
                        type="button"
                        color="primary"
                        aria-label="Save"
                        data-testid="save-button"
                        disabled={!isValid || isSubmitting}
                        onClick={() => {
                          setSubmitType("save");
                          submitForm().catch((error) => {
                            return console.error(error);
                          });
                        }}
                        style={settings ? {} : { marginRight: "10px" }}
                        variant={settings ? "contained" : undefined}
                      >
                        {savedReply ? "Save" : "Create"}
                      </Button>
                      {!settings && (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          disabled={!isValid || isSubmitting}
                          onClick={() => {
                            setSubmitType("saveAndUse");
                            submitForm().catch((error) => {
                              return console.error(error);
                            });
                          }}
                        >
                          {savedReply ? "Save & Use" : "Create & Use"}
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </FormWrapper>
  );
}

export default SavedReplyForm;

SavedReplyForm.propTypes = {
  currentAccount: PropTypes.object.isRequired, // withConnect
  currentUser: PropTypes.object.isRequired, // withConnect
  fetchSavedReplyCollectionRequest: PropTypes.func.isRequired, // withRecord
  handleCloseModal: PropTypes.func, // passed
  handleSavedReplySelected: PropTypes.func.isRequired, // passed
  savedReplies: PropTypes.object, // passed
  savedReply: PropTypes.object, // passed
  savedReplyCollectionId: PropTypes.string.isRequired, // passed
  setActiveSavedReply: PropTypes.func.isRequired, // passed
  settings: PropTypes.bool, // passed
  settingsAccount: PropTypes.object, // passed
  setSavedReplies: PropTypes.func, // passed
};
