import { Box, Typography, useMediaQuery, styled, Theme } from "@mui/material";
import { NavLink } from "react-router-dom";
import { compose } from "redux";
import { CampaignFilterCollectionProps } from "./types";
import { campaignFilterSlugs } from "features/Campaigns/constants";
import withRecord from "higherOrderComponents/withRecord";
import { useCurrentAccount } from "hooks";

const StyledNavLink = styled(NavLink)(({ theme }) => {
  return {
    width: "100%",
    cursor: "pointer",
    padding: "0.5rem 1rem",
    textDecoration: "none",
    background: "none",
    "&.active": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  };
});

function CampaignFilterCollection({
  campaignFilterCollection,
  toggleSidebar,
}: CampaignFilterCollectionProps) {
  const currentAccount = useCurrentAccount();

  const { campaignsResponsiveness = false } =
    currentAccount?.featureFlags ?? {};

  const isSinglePanel = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("md");
  });

  const { all, mine, allScheduled, myScheduled } = campaignFilterSlugs;

  const handleClick = () => {
    if (isSinglePanel) {
      toggleSidebar();
    }
  };

  const filteredCampaignFilterCollection = currentAccount.multiUser
    ? campaignFilterCollection.members.filter(({ slug }) => {
        return slug === all || slug === allScheduled;
      })
    : campaignFilterCollection.members.filter(({ slug }) => {
        return slug === mine || slug === myScheduled;
      });

  const flaggedCampaignFilterCollection = campaignsResponsiveness
    ? filteredCampaignFilterCollection
    : campaignFilterCollection.members;

  return flaggedCampaignFilterCollection.map((campaignFilter) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        key={campaignFilter.slug}
      >
        <StyledNavLink
          to={`/${currentAccount.slug}/campaigns/${campaignFilter.slug}`}
          onClick={handleClick}
          data-testid="campaigns-sidebar-nav-link"
        >
          <Typography color="text.primary" variant="body1">
            {campaignFilter.title}
          </Typography>
        </StyledNavLink>
      </Box>
    );
  });
}

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "Campaigns/containers/CampaignFilterCollection",
    shape: { members: [] },
    type: "campaignFilterCollection",
  }),
)(CampaignFilterCollection);
